import React from "react";
import { Container, Flex, Image, Link, Box } from "theme-ui";
import Icon from "../components/shared/Icon";
import Layout from "../components/Layout";
import bgPattern from "../images/datahexagons.svg";

const NotFoundPage = () => (
  <Layout mainContainerClass="404">
    <Box sx={{
      background: `url(${bgPattern})`,
      backgroundRepeat: 'no-repeat',
      backgroundOrigin: 'padding-box',
      backgroundPosition: '100% 150%',
      width: '100vw'
    }}>
      <Container sx={{ height: "100%"}}>
        <Flex
          sx={{
            justifyContent: 'center',
            color: "#404041",
            position: "relative",
            height: "100%",
            paddingBotton:"48px",
          }}
        >
          <Box>
            <h1 className="errorHeader">404</h1>
            <h3 className="errorsubHeader">Well, this is unexpected.</h3>
            <p className="errortext">Sorry—we lost the plot. Can we interest you in one of these options?</p>
            <div><Link href="/" sx={{
              display: "inline-flex", gap: "15px", transition: ".5s", '&:hover': {
                gap: '25px',
              },
            }}><p className="errorLink">Great Expectations home</p><div className="erroricon"><Icon icon='bs-arrow-right' iconSize={18} /></div></Link>
            </div>
            <Link href="https://docs.greatexpectations.io/docs/" sx={{
              display: "inline-flex", gap: "15px", transition: ".5s", '&:hover': {
                gap: '25px',
              },
            }}><p className="errorLink">Search the GX documentation</p><div className="erroricon"><Icon icon='bs-arrow-right' iconSize={18} /></div></Link>
          </Box>
        </Flex>
      </Container>
    </Box>
  </Layout>
);

export default NotFoundPage;
